import { throttle } from "lodash"
import React, { useEffect } from "react"
import MenuContextProvider from "../../context/MenuContext"
// import Header from "../Header"

const Layout = ({ children, location }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      let el = document.getElementsByTagName("body")[0]
      window.addEventListener(
        "scroll",
        throttle(function () {
          if (window.pageYOffset > 30) {
            el.classList.add("squeeze")
          } else {
            el.classList.remove("squeeze")
          }
        }, 1000)
      )
    }
  }, [])

  return (
    <MenuContextProvider>
      {/* <Header location={location} /> */}
      <div className="wrap container-fluid p-0" role="document">
        <div className="content">
          <main className="main">{children}</main>
        </div>
      </div>
    </MenuContextProvider>
  )
}

export default Layout
