/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { MenuContext } from "../context/MenuContext"
import { createAbsoluteLink } from "../utils"

const SEO = ({ lang, meta, title, seo, bodyClasses = "" }) => {
  const dir = lang === "en" ? "ltr" : "rtl"
  const menuContext = useContext(MenuContext)
  const { isMenuOpen } = menuContext
  if (isMenuOpen) {
    bodyClasses = bodyClasses + " expandedMenu"
  }
  bodyClasses = `${bodyClasses + " " + dir}`
  // const { wp, wpUser } = useStaticQuery(
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          generalSettings {
            title
            description
          }
        }

        # if there's more than one user this would need to be filtered to the main user
        #wpUser {
        # twitter: name
        #}
      }
    `
  )

  const metaDescription = seo?.metaDesc || wp.generalSettings?.description
  // const defaultTitle = parse(seo?.title || wp.generalSettings?.title)
  title = parse(title || seo?.title || wp.generalSettings?.title)

  let seoImage =
    seo?.opengraphImage?.localFile?.publicURL || wp?.generalSettings?.image

  seoImage = createAbsoluteLink(seoImage)

  // console.log(lang)

  return (
    <Helmet
      htmlAttributes={{
        lang: lang,
        dir: dir,
      }}
      bodyAttributes={{
        class: bodyClasses,
        dir: dir,
      }}
      title={title}
      // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `plgbuzz`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: seoImage,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
  author: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
}

export default SEO

export const WpYoastSEO = graphql`
  fragment WpYoastSEO on WpPostTypeSEO {
    title
    metaDesc
    metaKeywords
    focuskw
    opengraphImage {
      sourceUrl
      localFile {
        publicURL
      }
    }
  }
`

export const WpTaxSEO = graphql`
  fragment WpTaxSEO on WpTaxonomySEO {
    title
    metaDesc
    metaKeywords
    focuskw
    opengraphImage {
      sourceUrl
      localFile {
        publicURL
      }
    }
  }
`
