import React, { createContext, useState } from "react"

export const MenuContext = createContext()

const MenuContextProvider = ({ children }) => {
  const [locale, setLocale] = useState("en")
  const toggleLocale = () => {
    setLocale({ locale: !locale })
  }
  // console.log(locale)
  return (
    <MenuContext.Provider value={{ locale, toggleLocale: toggleLocale }}>
      {children}
    </MenuContext.Provider>
  )
}

export default MenuContextProvider
